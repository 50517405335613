.card-image-max96 {
  max-width: 96px;
  max-height: 96px;
}

.list-group-item {
	width: 95%;
	margin: 1% !important;
}

@media (min-width: 576px) {
	.list-group-item {
		width: 47%;
		margin: 5px 1.5% !important;
	}
}

@media (min-width: 768px) {
	.list-group-item {
		width: 31.333%;
		margin: 5px 1% !important;
	}
}

@media (min-width: 992px) {
	.list-group-item {
		width: 23%;
		margin: 5px 1% !important;
	}
}

